import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CSectTitle03,
  CInfinitySlider03,
  COverflowTable,
  CSimpleCard,
  CTable,
  CTable03,
  CBtnList,
  CInlineLinkList,
  LVenue,
  CFixedImg,
  LBanquetContact,
} from '../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: (
              <>
                会議室 <br className="u_sp" />
                ミーティングルーム
              </>
            ),
            sub: <>MEETING ROOM</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/meetingroom/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/meetingroom/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <div className="u_pt50 u_mbLarge">
        <LWrap>
          <CInfinitySlider03
            data={[
              {
                img: {
                  src: '/assets/images/banquet/meetingroom/img_banquet.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/meetingroom/img_banquet02.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/banquet/meetingroom/img_banquet03.png',
                  alt: '',
                },
              },
            ]}
          />
          <p className="u_mbMedium">
            庭園の樹木を臨み、木目のインテリアが重厚感のあるハイクオリティなスペース。
            <br />
            スクリーンも常設されており、お打合せや商談、セミナーなどでご利用が可能です。
          </p>
        </LWrap>
      </div>

      <section>
        <LWrap>
          <CSectTitle03 title="主要設備" />
          <CTable03
            exClass="u_mb10"
            data={[
              {
                title: '営業時間･ご利用料金',
                content: (
                  <>
                    <p className="u_fwb">ご宿泊者</p>
                    <ul className="c_circleList u_mt5">
                      <li>時間 0:00~24:00</li>
                      <li>料金 2時間33,000円（30,000円）</li>
                      <li>延長 1時間につき16,500円（15,000円）</li>
                    </ul>
                    <p className="u_fwb u_mt30">外来のお客様</p>
                    <ul className="c_circleList u_mt5">
                      <li>時間 8:00~22:00</li>
                      <li>料金 2時間44,000円（40,000円）</li>
                      <li>延長 1時間につき22,000円（20,000円）</li>
                    </ul>
                  </>
                ),
              },
              {
                title: '設　備',
                content: (
                  <>
                    <ul className="c_circleList">
                      <li>スクリーン</li>
                    </ul>
                  </>
                ),
              },
            ]}
          />
          <ul className="c_noteList u_mb40">
            <li>料金には消費税が含まれます。(　)内は本体価格です。</li>
          </ul>
          <CBtnList
            data={[
              {
                label: 'CO₂ゼロ MICE®のご案内',
                size: 'large',
                link: {
                  href: '/banquet/co2zero_mice/',
                },
                color: 'borderBlack',
              },
            ]}
          />
          <CInlineLinkList
            exClass="u_mt30"
            data={[
              {
                label: 'Web会議システム',
                link: {
                  href: '/banquet/on-line_meeting/',
                  blank: false,
                },
                //icon: 'document',
              },
            ]}
          />
        </LWrap>
      </section>
      <LVenue />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宴会・会議',
              path: '/banquet/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
